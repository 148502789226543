import React, { useEffect, useState } from 'react';
import { Col, Divider, Row, Spin, Table, Tooltip } from 'antd';
import dayjs from 'dayjs';

import { showApiErrors } from '../../../utils/showApiErrors';
import { domainPurchaseService } from '../../../services/domain-purchase';
import { getUserFullName } from '../../../utils/functions';
import { IdentityPurpose } from '../../../services/identity';

import styles from './PurchaseDetails.module.css';

export const PurchaseDetails = ({ requestId }) => {
  const [purchaseDetails, setPurchaseDetails] = useState({});
  const [purchasedDomains, setPurchasedDomains] = useState([]);
  const [loadingPurchaseDetails, setLoadingPurchaseDetails] = useState(false);

  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    let timeoutId = null;
    let mounted = true;

    const getPurchaseDetails = async (initialLoad = false) => {
      if (requestId) {
        try {
          if (mounted) {
            if (initialLoad) {
              setLoadingPurchaseDetails(true);
            } else {
              setRefreshing(true);
            }
          }
          const details = await domainPurchaseService.getDetails(requestId);
          if (mounted) {
            setPurchaseDetails(details);
            setPurchasedDomains(details.domain_integrations);
            if (!(details.status === 'DONE' || details.status === 'FAILED')) {
              timeoutId = setTimeout(() => getPurchaseDetails(), 1000);
            }
          }
        } catch (e) {
          if (mounted) {
            showApiErrors(e);
            setPurchaseDetails({});
            setPurchasedDomains([]);
          }
        } finally {
          if (mounted) {
            if (initialLoad) {
              setLoadingPurchaseDetails(false);
            } else {
              setRefreshing(false);
            }
          }
        }
      }
    };

    getPurchaseDetails(true);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      mounted = false;
    };
  }, [requestId]);

  const columns = [
    { title: 'Domain', dataIndex: 'domain_name', sorter: true },
    { title: 'Identity', dataIndex: 'identity_details', sorter: true, render: (identity_details) => identity_details.name },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      sorter: true,
      render: (status) => (
        <Spin spinning={refreshing && !(status === 'DONE' || status === 'FAILED')} size="small">
          {status}
        </Spin>
      )
    },
    {
      title: 'Details',
      dataIndex: 'additional_details',
      render: (details) => {
        const details_str = (details && JSON.stringify(details)) || '';
        if (details_str.length > 100) {
          return (
            <Tooltip title={details_str} mouseEnterDelay={0.5}>
              {`${details_str.substring(0, 100)}...`}
            </Tooltip>
          );
        }

        return details_str;
      }
    }
  ];

  return (
    <Spin spinning={loadingPurchaseDetails}>
      <h1>Purchase details ({requestId})</h1>
      <Row gutter={32}>
        <Col span={6}>
          <span className={styles.details}>
            <b>Status:</b>
            <Spin spinning={refreshing} size="small">
              {purchaseDetails.status || 'Unknown'}
            </Spin>
          </span>
        </Col>
        <Col span={6}>
          <span className={styles.details}>
            <b>Buyer:</b> {getUserFullName(purchaseDetails.creator_details)}
          </span>
        </Col>
        <Col span={6}>
          <span className={styles.details}>
            <b>Purpose:</b>{' '}
            {purchaseDetails.purpose === IdentityPurpose.AzureWebPush ? 'Azure WebPush' : purchaseDetails.purpose || 'Unknown'}
          </span>
        </Col>
        <Col span={6}>
          <span className={styles.details}>
            <b>Purchase date:</b> {dayjs(purchaseDetails.date_created || 0).format('DD.MM.YYYY. - HH:mm:ss')}
          </span>
        </Col>
      </Row>
      <Divider />
      <Table title={() => <b>Domains:</b>} columns={columns} rowKey={(row) => row.id} dataSource={purchasedDomains} />
    </Spin>
  );
};
