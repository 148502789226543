import { productsService } from '../../services/products';
import { accountsService } from '../../services/accounts';
import { listsService } from '../../services/lists';
import { mediaCampaignsService } from '../../services/media-campaigns';
import { landingPagesService } from '../../services/landingPages';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { browsersService } from '../../services/browsers';
import { countriesService } from '../../services/countries';
import { osService } from '../../services/os';
import { domainPurchaseService } from '../../services/domain-purchase';

// TODO: Take object parameter
export const useCustomQuery = (cb, params = undefined, initialData = [], options = { enabled: true }) => {
  // NOTE: This is hack as react-query doesn't fetch for first time if has initialData,
  // it thinks initialData substitute fetching, this is not case with our app
  const [initialDataStable] = useState(initialData);

  const queryData = { ...useQuery(...cb(params), options) };
  return { ...queryData, data: queryData.data ?? initialDataStable };
};

export const getProducts = (params) => {
  const tags = [QUERIES.Products, 'all', JSON.stringify(params)];
  return [tags, () => productsService.getAll(params)];
};

export const getAccounts = (params) => {
  const tags = [QUERIES.Accounts, 'all', JSON.stringify(params)];
  return [tags, () => accountsService.getAll(params)];
};

export const getLPs = (params) => {
  const tags = [QUERIES.LPs, 'all', JSON.stringify(params)];
  return [tags, () => landingPagesService.getAll(params)];
};

export const getCountryLists = (params) => {
  const tags = [QUERIES.CountryLists, 'all', JSON.stringify(params)];
  return [tags, () => listsService.getCountryLists(params)];
};

export const getCountryList = (id) => {
  const tags = [QUERIES.CountryList, id];
  return [tags, () => listsService.getCountryList(id)];
};

export const getCountriesAndCountriesLists = (params) => {
  const tags = [QUERIES.CountriesAndCountriesLists, 'all', JSON.stringify(params)];
  return [tags, () => listsService.getCountryAndCountryLists(params)];
};

export const getOSLists = (params) => {
  const tags = [QUERIES.OSLists, 'all', JSON.stringify(params)];
  return [tags, () => listsService.getOSLists(params)];
};

export const getOSList = (id) => {
  const tags = [QUERIES.OSList, id];
  return [tags, () => listsService.getOSList(id)];
};

export const getOSAndOSLists = (params) => {
  const tags = [QUERIES.OSAndOSLists, 'all', JSON.stringify(params)];
  return [tags, () => listsService.getOSAndOSLists(params)];
};

export const getCountries = (params) => {
  const tags = [QUERIES.Countries, 'all', JSON.stringify(params)];
  return [tags, () => countriesService.getAll(params)];
};

export const getOSs = (params) => {
  const tags = [QUERIES.OSs, 'all', JSON.stringify(params)];
  return [tags, () => osService.getAll(params)];
};

export const getBrowsers = (params) => {
  const tags = [QUERIES.Browsers, 'all', JSON.stringify(params)];
  return [tags, () => browsersService.getAll(params)];
};

export const getBrowserLists = (params) => {
  const tags = [QUERIES.BrowserLists, 'all', JSON.stringify(params)];
  return [tags, () => listsService.getBrowserLists(params)];
};

export const getBrowserList = (id) => {
  const tags = [QUERIES.BrowserList, id];
  return [tags, () => listsService.getBrowserList(id)];
};

export const getBrowsersAndBrowsersLists = (params) => {
  const tags = [QUERIES.BrowsersAndBrowsersLists, 'all', JSON.stringify(params)];
  return [tags, () => listsService.getBrowserAndBrowserLists(params)];
};

export const getSubidLists = (params) => {
  const tags = [QUERIES.SubidLists, 'all', JSON.stringify(params)];
  return [tags, () => listsService.getSubidLists(params)];
};

export const getSubidList = (id) => {
  const tags = [QUERIES.SubidList, id];
  return [tags, () => listsService.getSubidList(id)];
};

export const getDeviceTypeLists = (params) => {
  const tags = [QUERIES.DeviceTypeLists, 'all', JSON.stringify(params)];
  return [tags, () => listsService.getDeviceTypeLists(params)];
};

export const getDeviceTypeList = (id) => {
  const tags = [QUERIES.DeviceTypeList, id];
  return [tags, () => listsService.getDeviceTypeList(id)];
};

export const getGlobalPriceLists = (params) => {
  const tags = [QUERIES.GlobalPriceLists, 'all', JSON.stringify(params)];
  return [tags, () => listsService.getGlobalPriceLists(params)];
};

export const getSetupTypes = (params) => {
  const tags = [QUERIES.SetupTypes, 'all', JSON.stringify(params)];
  return [tags, () => mediaCampaignsService.getCampaignPatternTypes(params)];
};

export const getLandingPages = (params) => {
  const tags = [QUERIES.LandingPages, 'all', JSON.stringify(params)];
  return [tags, () => landingPagesService.getAll(params)];
};

export const getGlobalTargetingLists = (params) => {
  const tags = [QUERIES.GlobalTargetingLists, 'all', JSON.stringify(params)];
  return [tags, () => listsService.getGlobalTargetingLists(params)];
};

export const getExternalUrls = (params) => {
  const tags = [QUERIES.ExternalUrls, 'all', JSON.stringify(params)];
  return [tags, () => mediaCampaignsService.getExternalUrl(params)];
};

export const getMediaCampaigns = (params) => {
  const tags = [QUERIES.MediaCampaigns, 'all', JSON.stringify(params)];
  return [tags, () => mediaCampaignsService.getCampaigns(params)];
};

const initialPaginatedData = { results: [], count: 0 };

export const useFetchDomainPurchases = (params) => {
  return useQuery([QUERIES.DomainPurchases, params], ({ signal }) => domainPurchaseService.getPaged({ ...params, cancelToken: signal }), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    placeholderData: initialPaginatedData
  });
};

const QUERIES = {
  Products: 'Products',
  Accounts: 'Accounts',
  LPs: 'LPs',
  Countries: 'Countries',
  CountryLists: 'CountryLists',
  CountryList: 'CountryList',
  CountriesAndCountriesLists: 'CountriesAndCountriesLists',
  OSs: 'OSs',
  OSLists: 'OSLists',
  OSList: 'OSList',
  OSAndOSLists: 'OSAndOSLists',
  Browsers: 'Browsers',
  BrowserLists: 'BrowserLists',
  BrowserList: 'BrowserList',
  BrowsersAndBrowsersLists: 'BrowsersAndBrowsersLists',
  SubidLists: 'SubidLists',
  SubidList: 'SubidList',
  DeviceTypeLists: 'DeviceTypeLists',
  DeviceTypeList: 'DeviceTypeList',
  GlobalPriceLists: 'GlobalPriceLists',
  SetupTypes: 'SetupTypes',
  LandingPages: 'LandingPages',
  GlobalTargetingLists: 'GlobalTargetingLists',
  ExternalUrls: 'ExternalUrls',
  MediaCampaigns: 'MediaCampaigns',
  DomainPurchases: 'DomainPurchases'
};
