import { callApi } from './api';
import { getQueryString, getOffsetLimitFromPagination } from '../utils/query';
import { handleOrdering } from '../utils/functions';

export const ChannelColumnGroups = {
  Dimensions: 'Dimensions',
  Metrics: 'Metrics',
  CampaignInfo: 'Campaign info',
  Pixels: 'Pixels',
  FiredPixels: 'Fired pixels',
  PixelsEarned: 'Pixels Earned',
  Product: 'Product',
  PartnerInfo: 'Partner info',
  QSParameters: 'QS parameters',
  EcommerceMetrics: 'E-commerce Metrics'
};

export const allChannelStatColumns = [
  { value: 'date', text: 'Date', group: ChannelColumnGroups.Dimensions },
  { value: 'hour', text: 'Hour', group: ChannelColumnGroups.Dimensions },
  {
    value: 'campaign_id',
    text: 'Campaign ID',
    group: ChannelColumnGroups.CampaignInfo
  },
  { value: 'campaign_title', text: 'Campaign Title', group: ChannelColumnGroups.CampaignInfo },
  { value: 'campaign_type', text: 'Campaign Type', group: ChannelColumnGroups.CampaignInfo },
  { value: 'campaign_status', text: 'Campaign Status', group: ChannelColumnGroups.CampaignInfo },
  { value: 'rule_type', text: 'Rule Type', group: ChannelColumnGroups.CampaignInfo },
  { value: 'rule_type_id', text: 'Rule Type ID', group: ChannelColumnGroups.CampaignInfo },
  { value: 'lp_title', text: 'LP Title', group: ChannelColumnGroups.CampaignInfo },
  { value: 'lp_id', text: 'LP ID', group: ChannelColumnGroups.CampaignInfo },
  { value: 'app_id', text: 'App ID', group: ChannelColumnGroups.CampaignInfo },
  { value: 'identity', text: 'Identity', group: ChannelColumnGroups.CampaignInfo },
  { value: 'host', text: 'Domain', group: ChannelColumnGroups.CampaignInfo },
  { value: 'os', text: 'OS', group: ChannelColumnGroups.Dimensions },
  { value: 'os_version', text: 'OS Version', group: ChannelColumnGroups.Dimensions },
  { value: 'browser', text: 'Browser', group: ChannelColumnGroups.Dimensions },
  {
    value: 'browser_version',
    text: 'Browser Version',
    group: ChannelColumnGroups.Dimensions
  },
  { value: 'country', text: 'Country', group: ChannelColumnGroups.Dimensions },
  { value: 'source', text: 'Source', group: ChannelColumnGroups.Dimensions },
  { value: 'channel', text: 'Channel', group: ChannelColumnGroups.Dimensions },
  {
    value: 'creator_first_name',
    text: 'Account manager',
    group: ChannelColumnGroups.Dimensions
  },
  { value: 'product_id', text: 'Product ID', group: ChannelColumnGroups.Product },
  { value: 'product_title', text: 'Product Title', group: ChannelColumnGroups.Product },
  {
    value: 'prod_company_name',
    text: 'Product Com. Name',
    group: ChannelColumnGroups.PartnerInfo
  },
  {
    value: 'adv_company_name',
    text: 'Adv. Company Name',
    group: ChannelColumnGroups.PartnerInfo
  },
  { value: 'prod_brought_by_first_name', text: 'Prod Com. Brought By', group: ChannelColumnGroups.PartnerInfo },
  { value: 'adv_brought_by_first_name', text: 'Traffic Comp. Brought By', group: ChannelColumnGroups.PartnerInfo },
  { value: 'im', text: 'Impressions', group: ChannelColumnGroups.Metrics },
  { value: 'ri', text: 'Redirect impressions', group: ChannelColumnGroups.Metrics },
  { value: 'dw', text: 'Downloads', group: ChannelColumnGroups.Metrics },
  { value: 'pl', text: 'Leads', group: ChannelColumnGroups.Pixels },
  { value: 'pf', text: 'Fired', group: ChannelColumnGroups.FiredPixels },
  { value: 'p1', text: 'PX 1', group: ChannelColumnGroups.Pixels },
  { value: 'p2', text: 'PX 2', group: ChannelColumnGroups.Pixels },
  { value: 'p3', text: 'PX 3', group: ChannelColumnGroups.Pixels },
  { value: 'p4', text: 'PX 4', group: ChannelColumnGroups.Pixels },
  {
    value: 'p5',
    text: 'PX 5',
    group: ChannelColumnGroups.Pixels
  },
  {
    value: 'p6',
    text: 'PX 6',
    group: ChannelColumnGroups.Pixels
  },
  {
    value: 'p7',
    text: 'PX 7',
    group: ChannelColumnGroups.Pixels
  },
  {
    value: 'p8',
    text: 'PX 8',
    group: ChannelColumnGroups.Pixels
  },
  { value: 'p1_spam', text: 'PX 1 Spam', group: ChannelColumnGroups.Pixels },
  { value: 'p1_not_spam', text: 'PX 1 Not Spam', group: ChannelColumnGroups.Pixels },
  { value: 'spent', text: 'Spent', group: ChannelColumnGroups.Metrics },
  { value: 'earned', text: 'Earned', group: ChannelColumnGroups.Metrics },
  { value: 'profit', text: 'Profit', group: ChannelColumnGroups.Metrics },
  { value: 'roi', text: 'ROI', group: ChannelColumnGroups.Metrics },
  { value: 'cpm', text: 'CPM', group: ChannelColumnGroups.Metrics },
  { value: 'rpm', text: 'RPM', group: ChannelColumnGroups.Metrics },
  { value: 'ctr', text: 'CTR', group: ChannelColumnGroups.Metrics },
  { value: 'prpm', text: 'Partner RPM', group: ChannelColumnGroups.Metrics },
  { value: 'rpl', text: 'RPL', group: ChannelColumnGroups.Metrics },
  { value: 'partner_rpl', text: 'Partner RPL', group: ChannelColumnGroups.Metrics },
  { value: 'ecpa', text: 'eCPA', group: ChannelColumnGroups.Metrics },
  { value: 'cr', text: 'CR', group: ChannelColumnGroups.Metrics },
  { value: 'pcr', text: 'Partner CR', group: ChannelColumnGroups.Metrics },
  { value: 'pcrf8', text: 'Partner CR PX8', group: ChannelColumnGroups.Metrics },
  { value: 'f1', text: 'PF 1', group: ChannelColumnGroups.FiredPixels },
  { value: 'f2', text: 'PF 2', group: ChannelColumnGroups.FiredPixels },
  { value: 'f3', text: 'PF 3', group: ChannelColumnGroups.FiredPixels },
  { value: 'f4', text: 'PF 4', group: ChannelColumnGroups.FiredPixels },
  { value: 'f5', text: 'PF 5', group: ChannelColumnGroups.FiredPixels },
  { value: 'f6', text: 'PF 6', group: ChannelColumnGroups.FiredPixels },
  { value: 'f7', text: 'PF 7', group: ChannelColumnGroups.FiredPixels },
  { value: 'f8', text: 'PF 8', group: ChannelColumnGroups.FiredPixels },
  { value: 'real_fired', text: 'Real Fired', group: ChannelColumnGroups.FiredPixels },
  { value: 'pl__earned', text: 'Lead Earned', group: ChannelColumnGroups.PixelsEarned },
  { value: 'p1__earned', text: 'PX 1 Earned', group: ChannelColumnGroups.PixelsEarned },
  { value: 'p2__earned', text: 'PX 2 Earned', group: ChannelColumnGroups.PixelsEarned },
  { value: 'p3__earned', text: 'PX 3 Earned', group: ChannelColumnGroups.PixelsEarned },
  { value: 'p4__earned', text: 'PX 4 Earned', group: ChannelColumnGroups.PixelsEarned },
  {
    value: 'p5__earned',
    text: 'PX 5 Earned',
    group: ChannelColumnGroups.PixelsEarned
  },
  {
    value: 'p6__earned',
    text: 'PX 6 Earned',
    group: ChannelColumnGroups.PixelsEarned
  },
  {
    value: 'p7__earned',
    text: 'PX 7 Earned',
    group: ChannelColumnGroups.PixelsEarned
  },
  {
    value: 'p8__earned',
    text: 'PX 8 Earned',
    group: ChannelColumnGroups.PixelsEarned
  },
  { value: 'wp__earned', text: 'WebPush Earned', group: ChannelColumnGroups.PixelsEarned },
  { value: 'wp_loop__earned', text: 'WebPush Loop Earned', group: ChannelColumnGroups.PixelsEarned },
  { value: 'search_rpl', text: 'Search RPL (p5_earned/p5)', group: ChannelColumnGroups.Metrics },
  { value: 'rpl_px8', text: 'RPL PX8', group: ChannelColumnGroups.Metrics },
  { value: 'cr_px1', text: 'CR PX1', group: ChannelColumnGroups.Metrics },
  { value: 'cr_px2', text: 'CR PX2', group: ChannelColumnGroups.Metrics },
  { value: 'cr_px3', text: 'CR PX3', group: ChannelColumnGroups.Metrics },
  { value: 'cr_px4', text: 'CR PX4', group: ChannelColumnGroups.Metrics },
  { value: 'cr_px5', text: 'CR PX5', group: ChannelColumnGroups.Metrics },
  { value: 'cr_px6', text: 'CR PX6', group: ChannelColumnGroups.Metrics },
  { value: 'cr_px7', text: 'CR PX7', group: ChannelColumnGroups.Metrics },
  { value: 'cr_px8', text: 'CR PX8', group: ChannelColumnGroups.Metrics },
  { value: 'ecpa_px5', text: 'eCPA (px5)', group: ChannelColumnGroups.Metrics },
  { value: 'cr_px5_px6', text: 'CR (px5/px6)', group: ChannelColumnGroups.Metrics },
  { value: 'pub_module', text: 'Publisher Module', group: ChannelColumnGroups.PartnerInfo },
  {
    value: 'creator_last_name',
    hidden: true
  },
  {
    value: 'prod_brought_by_last_name',
    hidden: true
  },
  {
    value: 'adv_brought_by_last_name',
    hidden: true
  },
  {
    value: 'channel_id',
    hidden: true
  },
  { value: 'source_1', text: 'QS 1', group: ChannelColumnGroups.QSParameters },
  { value: 'source_2', text: 'QS 2', group: ChannelColumnGroups.QSParameters },
  { value: 'source_3', text: 'QS 3', group: ChannelColumnGroups.QSParameters },
  { value: 'source_4', text: 'QS 4', group: ChannelColumnGroups.QSParameters },
  { value: 'source_5', text: 'QS 5', group: ChannelColumnGroups.QSParameters },
  { value: 'source_6', text: 'QS 6', group: ChannelColumnGroups.QSParameters },
  { value: 'source_7', text: 'QS 7', group: ChannelColumnGroups.QSParameters },
  { value: 'source_8', text: 'QS 8', group: ChannelColumnGroups.QSParameters },
  { value: 'source_9', text: 'QS 9', group: ChannelColumnGroups.QSParameters },
  { value: 'peh', text: 'PX Hold', group: ChannelColumnGroups.EcommerceMetrics },
  { value: 'ped', text: 'PX Declined', group: ChannelColumnGroups.EcommerceMetrics },
  { value: 'pec', text: 'PX Confirmed', group: ChannelColumnGroups.EcommerceMetrics },
  { value: 'feh', text: 'PF Hold', group: ChannelColumnGroups.EcommerceMetrics },
  { value: 'fed', text: 'PF Declined', group: ChannelColumnGroups.EcommerceMetrics },
  { value: 'fec', text: 'PF Confirmed', group: ChannelColumnGroups.EcommerceMetrics },
  { value: 'peh__amount', text: 'PX Hold Amount', group: ChannelColumnGroups.EcommerceMetrics },
  { value: 'ped__amount', text: 'PX Declined Amount', group: ChannelColumnGroups.EcommerceMetrics },
  { value: 'pec__amount', text: 'PX Confirmed Amount', group: ChannelColumnGroups.EcommerceMetrics },
  { value: 'feh__amount', text: 'PF Hold Amount', group: ChannelColumnGroups.EcommerceMetrics },
  { value: 'fed__amount', text: 'PF Declined Amount', group: ChannelColumnGroups.EcommerceMetrics },
  { value: 'fec__amount', text: 'PF Confirmed Amount', group: ChannelColumnGroups.EcommerceMetrics },
  { value: 'prod_tags', text: 'Product Com. Tags', group: ChannelColumnGroups.PartnerInfo },
  { value: 'adv_tags', text: 'Traffic Com. Tags', group: ChannelColumnGroups.PartnerInfo },
  {
    value: 'feed_id',
    text: 'Feed Id',
    group: ChannelColumnGroups.Dimensions
  }
];

export const FilterName = {
  OSs: 'os',
  OSVersions: 'os_version',
  Browsers: 'browser',
  BrowserVersions: 'browser_version',
  DeviceTypes: 'device_type',
  Identities: 'identity_id',
  Countries: 'country',
  Channels: 'campaign_id',
  LPs: 'lp_id',
  RuleTypes: 'rule_type_id',
  Types: 'type',
  Products: 'product_id',
  AccountManagers: 'creator_id',
  TrafficCompanies: 'adv_company_id',
  ProductCompanies: 'prod_company_id',
  NonProfit: 'non_profit',
  Sources: 'source',
  SourcesSearchType: 'sources_search_type',
  PublisherModules: 'pub_module',
  CampaignTypes: 'campaign_type',
  CampaignTitles: 'campaign_title',
  CampaignStatuses: 'campaign_status',
  Sources1: 'source_1',
  Sources2: 'source_2',
  Sources3: 'source_3',
  Sources4: 'source_4',
  Sources5: 'source_5',
  Sources6: 'source_6',
  Sources7: 'source_7',
  Sources8: 'source_8',
  Sources9: 'source_9',
  CompanyCategory: 'company_category',
  Excluded: 'excluded',
  IncludeErrors: 'include_errors',
  BroughtBy: 'company_brought_by',
  Tags: 'tags',
  Domains: 'host'
};

export const SortDirection = {
  Descending: 'descend',
  Ascending: 'ascend'
};

export const CampaignType = {
  CPA: 'CPA',
  CPM: 'CPM',
  CPC: 'CPC',
  RS: 'RS'
};

export const SourcesSearchType = {
  Contains: 'contains',
  Exact: 'exact'
};

const getColumnsParameter = (columns, checkedColumns) => {
  const checkedColumnsData = columns.filter((column) => checkedColumns[column.value]);
  const checkedColumnsValues = checkedColumnsData.map((column) => column.value);
  return checkedColumnsValues.join(',');
};

const getCustomColumnsQuery = (customColumns, checkedColumns) => {
  let query = '';

  customColumns.forEach((column) => {
    const { value, relatedEvent, metricsAndValues } = column;

    if (checkedColumns[value]) {
      let metricsQuery = '';

      metricsAndValues.forEach(({ metric, comparationSign, numericValue }) => {
        metricsQuery += `,${metric + comparationSign}=${numericValue}`;
      });

      query += `&${value}=${relatedEvent}${metricsQuery}`;
    }
  });

  return query;
};

class StatisticServices {
  async channelChart(filters, perHour, dateRange, includeErrors) {
    const { metrics } = filters;
    const perHourQuery = perHour ? ',hour' : '';
    const metricsQuery = metrics ? ',' + metrics.join(',') : '';

    let query = getQueryString(
      {
        columns: `date${perHourQuery}${metricsQuery}`,
        date_from: dateRange.from.format('YYYY-MM-DD'),
        date_to: dateRange.to.format('YYYY-MM-DD'),
        os: filters[FilterName.OSs] && filters[FilterName.OSs].join(','),
        os_version: filters[FilterName.OSVersions] && filters[FilterName.OSVersions].join(','),
        browser: filters[FilterName.Browsers] && filters[FilterName.Browsers].join(','),
        country: filters[FilterName.Countries] && filters[FilterName.Countries].join(','), // ex filters.countries
        campaign_id: filters[FilterName.Channels] && filters[FilterName.Channels].join(','), // ex filters.channels
        lp_id: filters[FilterName.LPs] && filters[FilterName.LPs].join(','), // ex filters.lps_id,
        product_id: filters[FilterName.Products] && filters[FilterName.Products].join(','), // ex filters.products
        creator_id: filters[FilterName.AccountManagers] && filters[FilterName.AccountManagers].join(','),
        adv_company_id: filters[FilterName.TrafficCompanies] && filters[FilterName.TrafficCompanies].join(','),
        prod_company_id: filters[FilterName.ProductCompanies] && filters[FilterName.ProductCompanies].join(','),
        source: filters[FilterName.Sources] && filters[FilterName.Sources].join(','),
        pub_module: filters[FilterName.PublisherModules] && filters[FilterName.PublisherModules].join(','),
        skip_errors: !includeErrors
      },
      true
    );

    // NOTE: quick hack for old stats
    const oldStats = new URLSearchParams(window.location.search).get('old_stats');
    const url = oldStats !== null ? 'stats' : 'ccss_stats';
    return await callApi({ url: `/api/channels/${url}?resType=chart&${query}` });
  }

  async getFlaggingStatsPaged({ selectedFilters, currentPage, pageSize, sorting, cancelToken }) {
    const { filters, dateRange, checkedColumns } = selectedFilters;
    const colParameter = Object.keys(checkedColumns)
      .filter((key) => checkedColumns[key])
      .join(',');
    const ordering = handleOrdering(sorting);
    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      fields: colParameter,
      date_from: dateRange.from.format('YYYY-MM-DD'),
      date_to: dateRange.to.format('YYYY-MM-DD'),
      identity__in: filters.identity && filters.identity.join(','),
      test__in: filters.tests && filters.tests.join(','),
      search_input: filters.searchValue,
      ordering: ordering.join(',')
    });
    return await callApi({ url: `/api/domains/phishing/${query}`, cancelToken });
  }

  getChannelsQuery = ({ filters, asyncExport, customColumns, dateRange, checkedColumns, sorting }) => {
    const ordering = handleOrdering(sorting);
    const queryData = {
      columns: getColumnsParameter(allChannelStatColumns, checkedColumns),
      date_from: dateRange.from.format('YYYY-MM-DD'),
      date_to: dateRange.to.format('YYYY-MM-DD'),
      os: filters[FilterName.OSs] && filters[FilterName.OSs].join(','),
      os_version: filters[FilterName.OSVersions] && filters[FilterName.OSVersions].join(','),
      browser: filters[FilterName.Browsers] && filters[FilterName.Browsers].join(','),
      browser_version: filters[FilterName.BrowserVersions] && filters[FilterName.BrowserVersions].join(','),
      device_type: filters[FilterName.DeviceTypes] && filters[FilterName.DeviceTypes].join(','),
      identity_id: filters[FilterName.Identities] && filters[FilterName.Identities].join(','),
      host: filters[FilterName.Domains] && filters[FilterName.Domains].join(','),
      country: filters[FilterName.Countries] && filters[FilterName.Countries].join(','), // ex filters.countries
      campaign_id: filters[FilterName.Channels] && filters[FilterName.Channels].join(','), // ex filters.channels
      ordering: ordering && ordering.join(','),
      lp_id: filters[FilterName.LPs] && filters[FilterName.LPs].join(','), // ex filters.lps_id
      rule_type_id: filters[FilterName.RuleTypes] && filters[FilterName.RuleTypes].join(','),
      type: filters[FilterName.Types] && filters[FilterName.Types].join(','),
      product_id: filters[FilterName.Products] && filters[FilterName.Products].join(','), // ex filters.products
      creator_id: filters[FilterName.AccountManagers] && filters[FilterName.AccountManagers].join(','),
      adv_company_id: filters[FilterName.TrafficCompanies] && filters[FilterName.TrafficCompanies].join(','),
      prod_company_id: filters[FilterName.ProductCompanies] && filters[FilterName.ProductCompanies].join(','),
      company_brought_by: filters[FilterName.BroughtBy] && filters[FilterName.BroughtBy].join(','),
      tags: filters[FilterName.Tags],
      non_profit: filters[FilterName.NonProfit],
      pub_module: filters[FilterName.PublisherModules] && filters[FilterName.PublisherModules].join(','),
      campaign_type: filters[FilterName.CampaignTypes] && filters[FilterName.CampaignTypes].join(','),
      campaign_title: filters[FilterName.CampaignTitles] && filters[FilterName.CampaignTitles].join(','),
      campaign_status: filters[FilterName.CampaignStatuses] && filters[FilterName.CampaignStatuses].join(','),
      source_1: filters[FilterName.Sources1] && filters[FilterName.Sources1].join(','),
      source_2: filters[FilterName.Sources2] && filters[FilterName.Sources2].join(','),
      source_3: filters[FilterName.Sources3] && filters[FilterName.Sources3].join(','),
      source_4: filters[FilterName.Sources4] && filters[FilterName.Sources4].join(','),
      source_5: filters[FilterName.Sources5] && filters[FilterName.Sources5].join(','),
      source_6: filters[FilterName.Sources6] && filters[FilterName.Sources6].join(','),
      source_7: filters[FilterName.Sources7] && filters[FilterName.Sources7].join(','),
      source_8: filters[FilterName.Sources8] && filters[FilterName.Sources8].join(','),
      source_9: filters[FilterName.Sources9] && filters[FilterName.Sources9].join(','),
      custom_cols: getColumnsParameter(customColumns, checkedColumns),
      company_category: filters[FilterName.CompanyCategory],
      async_export: asyncExport,
      excluded: filters[FilterName.Excluded] && filters[FilterName.Excluded].join(','),
      skip_errors: !filters[FilterName.IncludeErrors]
    };

    const sources = filters[FilterName.Sources] && filters[FilterName.Sources].join(',');
    if (filters[FilterName.SourcesSearchType] === SourcesSearchType.Contains) {
      queryData.source__contains = sources;
    } else {
      queryData.source = sources;
    }

    return queryData;
  };

  async getChannelsPaged({ selectedFilters, asyncExport, customColumns, currentPage, pageSize, sorting, cancelToken }) {
    const { filters, dateRange } = selectedFilters;
    let { checkedColumns } = selectedFilters;

    if (checkedColumns.channel && !checkedColumns.campaign_id) {
      checkedColumns = { ...checkedColumns, campaign_id: true };
    }

    if (checkedColumns['creator_first_name']) {
      checkedColumns = { ...checkedColumns, creator_last_name: true };
    }
    if (checkedColumns['prod_brought_by_first_name']) {
      checkedColumns = { ...checkedColumns, prod_brought_by_last_name: true };
    }
    if (checkedColumns['adv_brought_by_first_name']) {
      checkedColumns = { ...checkedColumns, adv_brought_by_last_name: true };
    }

    let query = getQueryString(
      {
        ...this.getChannelsQuery({ filters, asyncExport, customColumns, dateRange, checkedColumns, sorting }),
        ...getOffsetLimitFromPagination({ pageSize, currentPage })
      },
      true
    );

    const customColumnsQuery = getCustomColumnsQuery(customColumns, checkedColumns);

    // NOTE: quick hack for old stats
    const oldStats = new URLSearchParams(window.location.search).get('old_stats');
    const url = oldStats !== null ? 'stats' : 'ccss_stats';
    return await callApi({ url: `/api/channels/${url}/?resType=table&${query}${customColumnsQuery}`, cancelToken });
  }

  getChannelsExportUrl({ filters, checkedColumns, dateRange, customColumns, sorting, resType }) {
    const query = getQueryString({ ...this.getChannelsQuery({ filters, customColumns, dateRange, checkedColumns, sorting }) }, true);
    const customColumnsQuery = getCustomColumnsQuery(customColumns, checkedColumns);

    // NOTE: quick hack for old stats
    const oldStats = new URLSearchParams(window.location.search).get('old_stats');
    const url = oldStats !== null ? 'stats' : 'ccss_stats';
    return `/api/channels/${url}/?resType=${resType}&${query}${customColumnsQuery}`;
  }

  async getGoogleSheetUrl({ filters, checkedColumns, dateRange, customColumns, sorting }) {
    return (
      await callApi({
        url: this.getChannelsExportUrl({ filters, checkedColumns, dateRange, customColumns, sorting, resType: 'google_sheet' })
      })
    ).url;
  }
}

export const statisticServices = new StatisticServices();
